import { Outlet } from 'react-router-dom';
//hooks
import useResponsive from '../hooks/useResponsive';
// @mui
import { styled } from '@mui/material/styles';
// components
import Logo from '../components/Logo';
// ----------------------------------------------------------------------
import Iconify from '../components/Iconify';
import { Avatar, Button, Link, Stack} from '@mui/material';



const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: '100%',
  position: 'absolute',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(5, 5, 0)
  },
  display: 'flex',
  justifyContent: 'space-between', 
  alignItems: 'center',
  padding: '10px',
  zIndex: '9999'
}));




// ----------------------------------------------------------------------

export default function LogoOnlyLayout() {

  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      <HeaderStyle>
        <Logo /> 
      

      <>
      <Stack direction="row" spacing={1}>
        {isDesktop ? 
          <Button variant='outlined' startIcon={<Iconify icon={'f7:doc-plaintext'}/>} component={Link} href="https://docs.omnibal.com/" target="_blank" rel="noopener" >Docs</Button>
          :
          <Avatar component={Link} href="https://docs.omnibal.com/" target="_blank" rel="noopener" sx={{ border: '0.01em solid',  color: '#ff3c4b', background: 'transparent', borderRadius: '50%', width: 34, height: 34 }}><Iconify icon={'f7:doc-plaintext'} width={20} height={20} /></Avatar>
        }  
        <Avatar component={Link} href="https://twitter.com/omnibalapp" target="_blank" rel="noopener" sx={{ border: '0.01em solid',  color: '#ff3c4b', background: 'transparent', borderRadius: '50%', width: 34, height: 34 }}><Iconify icon={'tabler:brand-x'} width={20} height={20} /></Avatar>
        <Avatar component={Link} href="https://discord.gg/7gBMZgUnmm" target="_blank" rel="noopener" sx={{ border: '0.01em solid',  color: '#ff3c4b', background: 'transparent', borderRadius: '50%', width: 34, height: 34 }}><Iconify icon={'ic:baseline-discord'} width={20} height={20} /></Avatar>  
      </Stack>
      </>
  

      </HeaderStyle>
      <Outlet />
    </>
  );
}
